<template>
  <PageBlocks
    v-if="content"
    :content="content"
    :error="error"
    :debug="false"
    type="home"
    class="home"
  >
    <template v-if="header" #header>
      <div class="home-supheader app-width-large app-gutter">
        <p class="home-supheader__text typo-header-italic">
          {{ header.supheader.text }}
        </p>

        <BlocksTextsButton
          v-if="header.supheader"
          :label="header.supheader.label"
          :url="header.supheader.url"
          type="right"
          class="home-supheader__button"
        />
      </div>

      <BlocksHeadersHeaderHero
        :title="header.title"
        :hero="header.hero"
      />
    </template>
  </PageBlocks>
</template>

<script setup>
import { getMediaFromData } from '~/data/media';
import { getPageFromData } from '~/data/page';

const url = '/api/homepage';
const { data, error } = await useAsyncData(`fetch_home`, () => $fetch(url));
const page = getPageFromData(data.value?.data);

await useSEO(page.seo);

const getPageData = (apiData) => {
  const data = apiData?.value?.data?.attributes;
  return data || null;
};

const content = computed(() => {
  const pageData = getPageData(data);
  return pageData.content;
});

const header = computed(() => {
  const pageData = getPageData(data);
  if (!pageData) return;

  const hero = getMediaFromData(pageData.hero);
  const title = pageData.title;

  const supheader = {};
  supheader.text = pageData.definition;

  if (pageData.featuredlink) {
    supheader.label = pageData.featuredlink.label;
    supheader.url = pageData.featuredlink.url;
  }

  return { hero, title, supheader };
});

</script>

<style lang="stylus" scoped>
.home {
  :deep(.page-blocks__content) {
    > .richtext {
      @extends .app-width-small
      margin-top 100px
      margin-bottom 50px

      +size(L) {
        margin-bottom 100px
        margin-left 0
        padding-left 126px
      }

      h2, h3 {
        @extends .typo-home-title
      }
    }

    > .title-arrow--hidden {
      @extends .typo-home-title, .app-width-small

      +size(L) {
        margin-left 0
        padding-left 126px
      }
    }

    > .button-flat {
      +size(L) {
        justify-content center
      }
    }
  }
}

.home-supheader {
  margin-top 50px
  margin-bottom @margin-top

  +size(L) {
    margin-top 70px
  }
}

.home-supheader__text {
  max-width 1090px
  margin-top 0
  margin-bottom 35px

  +size(L) {
    margin-bottom 50px
  }
}

:deep(.header-hero) {
  margin-bottom -100px // abort first collection margin top
}
</style>